import { FlueMetricsTimeUnitEnum } from "./FlueMetricsTimeUnit";
import { DateTime } from "luxon";
import { FlueId } from "../FlueId";

export interface FlueMetricNaturalKey {
  flueId: FlueId;
  unit: FlueMetricsTimeUnitEnum;
  time?: DateTime<true>;

  equals(other: FlueMetricNaturalKey): boolean;
}

export class FlueMetricNaturalKeyImpl implements FlueMetricNaturalKey {
  flueId: FlueId;
  unit: FlueMetricsTimeUnitEnum;
  time?: DateTime<boolean> | undefined;

  constructor(flueId: FlueId, unit: FlueMetricsTimeUnitEnum, time?: DateTime) {
    this.flueId = flueId;
    this.unit = unit;
    this.time = time;
  }

  static ever = (flueId: FlueId): FlueMetricNaturalKeyImpl =>
    new FlueMetricNaturalKeyImpl(flueId, FlueMetricsTimeUnitEnum.Ever);

  static sinceSweep = (flueId: FlueId): FlueMetricNaturalKeyImpl =>
    new FlueMetricNaturalKeyImpl(flueId, FlueMetricsTimeUnitEnum.SinceSweep);

  static year = (
    flueId: FlueId,
    time: DateTime<true> | number
  ): FlueMetricNaturalKeyImpl =>
    new FlueMetricNaturalKeyImpl(
      flueId,
      FlueMetricsTimeUnitEnum.Year,
      typeof time === "number" ? DateTime.local(time) : time
    );

  static month = (
    flueId: FlueId,
    time: DateTime<true> | [number, number]
  ): FlueMetricNaturalKeyImpl =>
    new FlueMetricNaturalKeyImpl(
      flueId,
      FlueMetricsTimeUnitEnum.Month,
      Array.isArray(time) ? DateTime.local(time[0], time[1]) : time
    );

  equals(other: FlueMetricNaturalKey): boolean {
    if (this.flueId !== other.flueId) {
      return false;
    } else if (this.unit !== other.unit) {
      return false;
    } else if (this.unit === FlueMetricsTimeUnitEnum.Ever) {
      return true;
    } else if (this.unit === FlueMetricsTimeUnitEnum.SinceSweep) {
      return true;
    } else if (this.time != null && other.time != null) {
      return this.unit === other.unit && this.time.equals(other.time);
    } else {
      return false;
    }
  }
}
