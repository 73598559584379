import { ResolutionType } from "@airmont/shared/ts/types";
import { TimeframeObject } from "./TimeframeObject";
import {
  _throw,
  IllegalArgumentError,
  IllegalStateError,
  UnsupportedOperationError,
} from "@airmont/shared/ts/utils/core";
import { DateTime } from "luxon";
import { TimeframeUnit } from "./TimeframeUnit";
import { DateTimeUtils, IntervalUtils } from "@airmont/shared/ts/utils/luxon";

export class TimeframeUtils {
  static expandUnit(unit: TimeframeUnit): TimeframeUnit {
    if (unit === "year") {
      return "month";
    } else if (unit === "month") {
      return "week";
    } else {
      throw new IllegalArgumentError("Cannot expand unit: " + unit);
    }
  }

  static expandWithinUnit(
    timeframe: TimeframeObject,
    options: { unit: TimeframeUnit }
  ): TimeframeObject {
    const start = timeframe.start;
    const endOfUnit = start.endOf(options.unit).plus({ millisecond: 1 });
    if (timeframe.end < endOfUnit) {
      return IntervalUtils.fromDateTimes(start, endOfUnit);
    }
    return timeframe;
  }

  static changeYear(
    timeframe: TimeframeObject,
    newYear: number,
    options: { maxEnd?: DateTime; disallowFuture: boolean }
  ): TimeframeObject {
    const start = timeframe.start.set({ year: newYear });
    const yearDiff = timeframe.start.year - newYear;
    const end = timeframe.end.minus({ year: yearDiff });
    return IntervalUtils.fromDateTimes(start, end);
  }

  static changeMonth(
    timeframe: TimeframeObject,
    newMonth: number,
    options: { maxEnd?: DateTime; disallowFuture: boolean }
  ): TimeframeObject {
    const start = timeframe.start.set({ month: newMonth });
    const end = timeframe.end.set({ month: newMonth });
    return IntervalUtils.fromDateTimes(start, end);
  }

  static ensureValidEnd(
    end: DateTime,
    options: { maxEnd?: DateTime; disallowFuture: boolean }
  ): DateTime {
    const now = DateTime.now();
    let validEnd = end;
    if (options.disallowFuture && end > now) {
      validEnd = now.endOf("day").plus({ millisecond: 1 });
    }
    if (options.maxEnd && validEnd > options.maxEnd) {
      validEnd = options.maxEnd;
    }
    return validEnd;
  }

  static resolveNowTimeframe(options: {
    unit: TimeframeUnit;
    maxEnd?: DateTime;
    disallowFuture: boolean;
  }): TimeframeObject {
    const now = DateTime.now();

    let end = DateTime.now().endOf(options.unit).plus({ millisecond: 1 });
    end = TimeframeUtils.ensureValidEnd(end, options);

    const start = now.startOf(options.unit);

    return IntervalUtils.fromDateTimes(start, end);
  }

  static resolvePreviousTimeframe(
    currTimeframe: TimeframeObject,
    options: { unit: TimeframeUnit }
  ): TimeframeObject {
    const newEnd = currTimeframe.start;
    const newStart = newEnd.minus({ [options.unit]: 1 });
    return IntervalUtils.fromDateTimes(newStart, newEnd);
  }

  static resolveNextTimeframe(
    currTimeframe: TimeframeObject,
    options: { unit: TimeframeUnit; maxEnd?: DateTime; disallowFuture: boolean }
  ): TimeframeObject {
    const now = DateTime.now();

    const newStart = currTimeframe.start?.plus({ [options.unit]: 1 });
    let newEnd = currTimeframe.end?.plus({ [options.unit]: 1 });
    newEnd = TimeframeUtils.ensureValidEnd(newEnd, options);

    if (options.disallowFuture && newStart > now) {
      return currTimeframe;
    }

    return IntervalUtils.fromDateTimes(newStart, newEnd);
  }

  static transformTimeframeToUnit(args: {
    timeframe: TimeframeObject;
    options: {
      maxEnd?: DateTime;
      disallowFuture: boolean;
    };
    newUnit: TimeframeUnit;
    oldUnit: TimeframeUnit;
  }): TimeframeObject {
    const { timeframe, options, newUnit, oldUnit } = args;
    const currStart =
      timeframe.start ??
      _throw(new IllegalStateError("timeframe.start is invalid"));
    const currEnd =
      timeframe.end ??
      _throw(new IllegalStateError("timeframe.end is invalid"));

    if (oldUnit === "month" && newUnit === "year") {
      const newStart = currStart.startOf("year");
      let newEnd = currEnd?.endOf(newUnit).plus({ millisecond: 1 });
      newEnd = TimeframeUtils.ensureValidEnd(newEnd, options);
      return IntervalUtils.fromDateTimes(newStart, newEnd);
    } else if (oldUnit === "year" && newUnit === "month") {
      const newStart = DateTimeUtils.fromObject({
        year: currStart.year,
        month: 1,
        day: 1,
      });

      let newEnd = newStart.endOf("month");
      newEnd = TimeframeUtils.ensureValidEnd(newEnd, options);

      return IntervalUtils.fromDateTimes(newStart, newEnd);
    } else {
      throw new UnsupportedOperationError(
        `oldUnit/newUnit combination not supported: ${args.oldUnit}/${args.newUnit}`
      );
    }
  }

  static unitToResolution(unit: TimeframeUnit): ResolutionType {
    if (unit === "year") {
      return ResolutionType.Year;
    } /*else if (unit === "quarter") {
      return ResolutionType.Quarter;
    } */ else if (unit === "month") {
      return ResolutionType.Month;
    } /*else if (unit === "week") {
      return ResolutionType.Week;
    }*/ else {
      throw new Error(
        "TimeframeUnit cannot be converted to ResolutionType: " + unit
      );
    }
  }
}
