import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid-premium";
import {
  _throw,
  IllegalStateError,
  MathUtils,
  notNullOrUndefined,
} from "@airmont/shared/ts/utils/core";
import { GridRowParams } from "@mui/x-data-grid/models/params/gridRowParams";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { GridActionsColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { FlueStatusDto } from "@airmont/firefly/chimney-insights/ts/domain";
import { streetAddressGridValueGetter } from "../shared/streetAddressGridValueGetter";
import { useTranslation } from "react-i18next";
import {
  ChimneyHatStringsEnum,
  ChimneySensorCableStatusEnum,
  ChimneySensorStatusEnum,
  FlueTypeStringsEnum,
  resolveChimneySensorCableStatusIcon,
  resolveChimneySensorStatusColor,
  resolveChimneySensorStatusIcon,
  YesNoUnknownStringEnum,
} from "@airmont/firefly/shared/ts/domain";
import { DateTime } from "luxon";
import { pathValueGetter } from "shared/ts/ui/x-data-grid";
import { DateTimeISO } from "@airmont/shared/ts/types";
import * as React from "react";
import { useMemo } from "react";
import { Stack, Tooltip } from "@mui/material";
import QuestionMarkRounded from "@mui/icons-material/QuestionMarkRounded";

export const useFlueStatusTableColumns = (args: {
  onViewOnClick: (params: GridRowParams<FlueStatusDto>) => void;
  onEditOnClick: (params: GridRowParams<FlueStatusDto>) => void;
}): Array<GridColDef<FlueStatusDto>> => {
  const { onViewOnClick, onEditOnClick } = args;
  const { t } = useTranslation("firefly-shared-ts-domain");

  return useMemo(
    () => [
      {
        field: "streetAddress",
        headerName: t("Street Address"),
        width: 200,
        pinnable: false,
        hideable: false,
        valueGetter: streetAddressGridValueGetter,
      },
      {
        field: "streetAddress.houseSection",
        headerName: "Benr.",
        description: t("House Section"),
        width: 75,
        sortable: false,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "flue.name",
        headerName: t("Flue"),
        sortable: false,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "postalAddress.code",
        headerName: t("Postal Code.short"),
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "postalAddress.place",
        headerName: t("Postal Place"),
        sortable: false,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "sensorFields.status",
        headerName: t("Sensor Status"),
        description: "Chimney Sensor Status",
        type: "string",
        align: "center",
        sortable: true,
        pinnable: true,
        valueGetter: pathValueGetter,
        renderCell: (params) => {
          const status = params.row.sensorFields
            ? ChimneySensorStatusEnum[params.row.sensorFields.status]
            : undefined;
          if (status == null) {
            return (
              <Tooltip title={t("No Sensor Installed")}>
                <QuestionMarkRounded
                  sx={{ fontSize: "small", verticalAlign: "middle" }}
                />
              </Tooltip>
            );
          }
          const Icon = resolveChimneySensorStatusIcon(status);
          const color = resolveChimneySensorStatusColor(status);
          return (
            <Tooltip
              title={t(
                `ChimneySensorStatus.${
                  status ? status : ChimneySensorStatusEnum.Unknown
                }`
              )}
            >
              <Icon sx={{ verticalAlign: "middle", color: color }} />
            </Tooltip>
          );
        },
      },
      {
        field: "sensorFields.cableStatus",
        headerName: t("Cable Status"),
        description: "Chimney Sensor Cable Status",
        type: "string",
        align: "center",
        sortable: true,
        pinnable: true,
        valueGetter: pathValueGetter,
        renderCell: (params) => {
          const status =
            params.row.sensorFields != null
              ? ChimneySensorCableStatusEnum[
                  params.row.sensorFields.cableStatus
                ]
              : undefined;
          if (status == null) {
            return (
              <Tooltip title={t("No Sensor Installed")}>
                <QuestionMarkRounded
                  sx={{ fontSize: "small", verticalAlign: "middle" }}
                />
              </Tooltip>
            );
          }
          const Icon = resolveChimneySensorCableStatusIcon(status);
          return (
            <Tooltip title={t(`ChimneySensorCableStatus.${status}`)}>
              <Stack
                direction={"row"}
                sx={{ m: "auto", width: "fit-content", alignItems: "center" }}
              >
                {Icon}{" "}
                <sub>{`${t(`ChimneySensorCableStatus.short.${status}`)}`}</sub>
              </Stack>
            </Tooltip>
          );
        },
      },
      {
        field: "metricValuesSinceSweep.sootIndex",
        headerName: t("Soot Index"),
        description: "Sot-indeks siden siste feiing",
        type: "number",
        width: 140,
        sortable: true,
        pinnable: false,
        valueGetter: (value, row, column, apiRef) =>
          MathUtils.round(pathValueGetter(value, row, column, apiRef)),
      },
      {
        field: "metricValuesSinceSweep.burnHourCount",
        headerName: t("Burn Hours"),
        description: "Antall fyringstimer siden siste feiing",
        type: "number",
        width: 140,
        sortable: true,
        pinnable: false,
        valueGetter: (value, row, column, apiRef) =>
          MathUtils.round(pathValueGetter(value, row, column, apiRef)),
      },
      {
        field: "metricValuesSinceSweep.burnCount",
        headerName: t("Burns"),
        description: "Antall fyringer siden siste feiing",
        type: "number",
        width: 140,
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "metricValuesSinceSweep.temperatureMax",
        headerName: t("Max Temp."),
        description: "Maksimum temperatur siden siste feiing",
        type: "number",
        width: 150,
        sortable: true,
        pinnable: false,
        valueGetter: (value, row, column, apiRef) =>
          MathUtils.round(pathValueGetter(value, row, column, apiRef)),
      },
      {
        field: "chimney.hat",
        headerName: t("Chimney Hat"),
        type: "string",
        sortable: true,
        pinnable: false,
        valueGetter: (value, row, column, apiRef) => {
          t(
            `ChimneyHat.${
              pathValueGetter(
                value,
                row,
                column,
                apiRef
              ) as ChimneyHatStringsEnum
            }`
          );
        },
      },
      {
        field: "fireplace.catalytic",
        headerName: t("Clean-burning Fireplace"),
        description: "Rentbrennende ilsted",
        type: "string",
        sortable: true,
        pinnable: false,
        valueGetter: (value, row, column, apiRef) =>
          pathValueGetter(
            value,
            row,
            column,
            apiRef,
            (value: YesNoUnknownStringEnum) => t(`YesNoUnknown.${value}`)
          ),
      },
      {
        field: "flue.type",
        headerName: t("Flue Type"),
        type: "string",
        width: 150,
        sortable: true,
        pinnable: false,
        valueGetter: (value, row, column, apiRef) =>
          pathValueGetter(
            value,
            row,
            column,
            apiRef,
            (value: FlueTypeStringsEnum) => t(`FlueType.${value}`)
          ),
      },
      {
        field: "flue.height",
        headerName: t("Height"),
        description: "Høyde fra ildsted til toppen of skorstein",
        type: "number",
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "flue.chimneyFireThreshold",
        headerName: t("Temperature Alarm Threshold"),
        type: "string",
        align: "right",
        sortable: true,
        pinnable: false,
        valueGetter: (value, row, column, apiRef) =>
          pathValueGetter(value, row, column, apiRef, (v2) =>
            notNullOrUndefined(v2, (it) => `${MathUtils.round(it)} °C`)
          ),
      },
      {
        field: "flue.lastChimneyFire",
        headerName: t("Last Chimney Fire"),
        type: "string",
        width: 160,
        sortable: true,
        pinnable: false,
        valueGetter: (value, row, column, apiRef) =>
          pathValueGetter(value, row, column, apiRef, (value) =>
            notNullOrUndefined(value, (it) => {
              const dateTime =
                DateTime.fromISO(it) ??
                _throw(new IllegalStateError("flue.lastChimneyFire"));
              return dateTime.toLocaleString(DateTime.DATE_SHORT);
            })
          ),
      },
      {
        field: "flue.estimatedSweepDate",
        headerName: t("Upcoming Sweep"),
        type: "string",
        align: "right",
        width: 160,
        sortable: true,
        pinnable: true,
        valueGetter: (value, row, column, apiRef) => {
          if (row.flue.estimatedSweepDate == null) {
            return undefined;
          }
          const dateTime =
            DateTime.fromISO(row.flue.estimatedSweepDate) ??
            _throw(new IllegalStateError("time"));
          return dateTime.year < 10000
            ? dateTime.toFormat("LLLL yyyy").capitalizeFirstLetter()
            : null;
        },
      },
      {
        field: "flue.lastSweepDate",
        headerName: t("Last Sweep"),
        type: "string",
        width: 160,
        sortable: true,
        pinnable: false,
        valueGetter: (value, row, column, apiRef) =>
          pathValueGetter(value, row, column, apiRef, (value) =>
            notNullOrUndefined(value, (it) => {
              const dateTime =
                DateTime.fromISO(it) ??
                _throw(new IllegalStateError("flue.lastSweepDate"));
              return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
            })
          ),
      },
      {
        field: "comment",
        headerName: t("Comment"),
        type: "string",
        sortable: true,
        pinnable: false,
      },
      {
        field: "tags",
        headerName: t("Tags"),
        type: "string",
        sortable: true,
        pinnable: false,
      },
      {
        field: "audit.created",
        headerName: t("Created"),
        type: "string",
        width: 160,
        sortable: true,
        pinnable: false,
        valueGetter: (value, row, column, apiRef) =>
          pathValueGetter(value, row, column, apiRef, (value: DateTimeISO) =>
            notNullOrUndefined(value, (it) => {
              const dateTime =
                DateTime.fromISO(it) ??
                _throw(new IllegalStateError("audit.created"));
              return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
            })
          ),
      },
      {
        field: "audit.createdBy",
        headerName: t("Created by"),
        type: "string",
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "audit.modified",
        headerName: t("Changed"),
        type: "string",
        width: 160,
        sortable: true,
        pinnable: false,
        valueGetter: (value, row, column, apiRef) =>
          pathValueGetter(value, row, column, apiRef, (value: DateTimeISO) =>
            notNullOrUndefined(value, (it) => {
              const dateTime =
                DateTime.fromISO(it) ??
                _throw(new IllegalStateError("audit.modified"));
              return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
            })
          ),
      },
      {
        field: "audit.modifiedBy",
        headerName: t("Changed by"),
        type: "string",
        sortable: true,
        pinnable: false,
        valueGetter: pathValueGetter,
      },
      {
        field: "actions",
        headerName: t("Actions"),
        type: "actions",
        width: 100,
        pinnable: false,
        hideable: false,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<VisibilityRoundedIcon />}
            label="Se"
            onClick={() => onViewOnClick(params)}
          />,
        ],
      } as GridActionsColDef,
    ],
    [onViewOnClick, t]
  );
};
