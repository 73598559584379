import { useMemo } from "react";
import { BurnQualityCountAggregateByTime } from "../BurnQualityCountAggregateByTime";
import { BurnQualityCountDataPoints } from "../BurnQualityCountDataPoints";
import { DateTimeUnit, Interval } from "luxon";
import { notUndef } from "@airmont/shared/ts/utils/core";

export const useDataSet = (
  aggregates: Array<BurnQualityCountAggregateByTime> | undefined,
  interval: Interval<true>,
  timeUnit: DateTimeUnit
): BurnQualityCountDataPoints => {
  const dataPoints = useMemo(() => {
    const resolvedDataPoints =
      notUndef(aggregates, (aggregates) =>
        BurnQualityCountDataPoints.fromAggregates(aggregates)
      ) ?? BurnQualityCountDataPoints.empty();
    console.log(
      "useDataSet: resolvedDataPoints: \n",
      resolvedDataPoints.toString(timeUnit)
    );
    return resolvedDataPoints;
  }, [aggregates, timeUnit]);

  return useMemo(() => {
    const limitedDataPoints = dataPoints.between(interval, timeUnit);
    console.log(
      "useDataSet: limitedDataPoints: \n",
      limitedDataPoints.toString(timeUnit)
    );
    return limitedDataPoints;
  }, [dataPoints, interval, timeUnit]);
};
