import { YAxisDefaultConfig } from "shared-ts-mui";
import { useMemo } from "react";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import {
  AxisConfig,
  ChartsYAxisProps,
  ScaleName,
} from "@mui/x-charts/models/axis";
import { TFunction } from "i18next";
import { BurnAssessmentColor } from "../BurnAssessmentColor";

export const useYAxis = (
  showIgnitionQualityCount: boolean,
  showGoodInPercent: boolean,
  t: TFunction<"firefly-shared-ts-domain">
): Array<YAxisDefaultConfig> => {
  return useMemo(() => {
    const yAxis: Array<
      MakeOptional<AxisConfig<ScaleName, any, ChartsYAxisProps>, "id">
    > = [];
    if (showIgnitionQualityCount) {
      yAxis.push({
        id: "burnCount",
        scaleType: "linear",
        label: t("Ignitions"),
        tickMinStep: 1,
      } as MakeOptional<AxisConfig<ScaleName, any, ChartsYAxisProps>, "id">);
    }
    if (showGoodInPercent) {
      yAxis.push({
        id: "percentage",
        scaleType: "linear",
        label: t("Percentage"),
        valueFormatter: (value) => `${value}%`,
        min: 0,
        max: 100,
        colorMap: !showIgnitionQualityCount
          ? {
              type: "continuous",
              min: 0,
              max: 100,
              color: [BurnAssessmentColor.Bad, BurnAssessmentColor.Good],
            }
          : undefined,
      });
    }

    return yAxis;
  }, [showIgnitionQualityCount, showGoodInPercent, t]);
};
