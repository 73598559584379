import React, { ReactNode, useCallback, useMemo } from "react";

export interface ModalContextType {
  modal?: ReactNode;
  setModal: (modal: ReactNode | undefined) => void;
  closeModal: () => void;
}

export const ModalContext = React.createContext(
  undefined as unknown as ModalContextType
);

export const AppLayoutModalProvider = (props: { children: ReactNode }) => {
  const [modal, setModal] = React.useState<ReactNode | undefined>(undefined);

  const handleCloseModal = useCallback(() => {
    setModal(undefined);
  }, [setModal]);
  const handleSetModal = useCallback(
    (modal: ReactNode | undefined) => {
      setModal(modal);
    },
    [setModal]
  );

  const value = useMemo(() => {
    return {
      modal: modal,
      setModal: handleSetModal,
      closeModal: handleCloseModal,
    };
  }, [handleCloseModal, handleSetModal, modal]);

  return (
    <ModalContext.Provider value={value}>
      {props.children}
    </ModalContext.Provider>
  );
};
