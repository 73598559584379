import { ArrayUtils } from "./ArrayUtils";

declare global {
  interface Array<T> {
    copyAndRemoveByPredicate(
      predicate: (item: T, index: number) => boolean
    ): Array<T>;

    findLastIndex(predicate: (item: T, index: number) => boolean): number;
  }
}

if (!Array.prototype.copyAndRemoveByPredicate) {
  Array.prototype.copyAndRemoveByPredicate = function <T>(
    predicate: (it: T, index: number) => boolean
  ) {
    return ArrayUtils.copyAndRemoveByPredicate(this, predicate);
  };
}

if (!Array.prototype.findLastIndex) {
  Array.prototype.findLastIndex = function <T>(
    predicate: (it: T, index: number) => boolean
  ) {
    return ArrayUtils.findLastIndex(this, predicate);
  };
}
