import { useMemo } from "react";
import { BurnQualityCountDataPoints } from "../BurnQualityCountDataPoints";

export const useResolveAverageGoodInPercent = (
  dataSet: BurnQualityCountDataPoints
) => {
  return useMemo(() => {
    return dataSet.avgGoodIgnitionsInPercent();
  }, [dataSet]);
};
