import { DateTimeISO } from "@airmont/shared/ts/types";
import { BurnQualityCount, BurnQualityCountDto } from "./BurnQualityCount";
import { DateTime } from "luxon";
import { _throw, IllegalStateError } from "@airmont/shared/ts/utils/core";

export interface BurnQualityCountAggregateByTimeDto {
  time: DateTimeISO;
  qualityCount: BurnQualityCountDto;
}

export class BurnQualityCountAggregateByTime {
  readonly time: DateTime<true>;
  readonly qualityCount: BurnQualityCount;

  public constructor(dto: BurnQualityCountAggregateByTimeDto) {
    this.time = DateTime.fromISO(dto.time, { zone: "utc" }) as DateTime<true>;
    this.qualityCount = new BurnQualityCount(dto.qualityCount);
  }

  public static of(
    time: DateTime,
    excellent: number,
    good: number,
    bad: number
  ): BurnQualityCountAggregateByTime {
    return new BurnQualityCountAggregateByTime({
      time: time.toISO() ?? _throw(new IllegalStateError()),
      qualityCount: {
        excellent: excellent,
        good: good,
        bad: bad,
        unknown: 0,
        total: excellent + good + bad,
      },
    });
  }

  public static debug(
    aggregates: Array<BurnQualityCountAggregateByTime>
  ): string {
    return aggregates?.reduce((a, b, index) => {
      return (
        a +
        `${b.time.toLocal().toFormat("yyyy-MM-dd (WW:ccc)")}: e:${
          b.qualityCount.excellent
        }, g:${b.qualityCount.good}, b:${b.qualityCount.bad}\n`
      );
    }, "");
  }
}
