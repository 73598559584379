import { BarSeriesType } from "@mui/x-charts/models/seriesType/bar";
import { LineSeriesType } from "@mui/x-charts/models/seriesType/line";
import { useMemo } from "react";
import { BurnAssessmentColor } from "../BurnAssessmentColor";
import { TFunction } from "i18next";

export const useSeries = (
  showIgnitionQualityCount: boolean,
  showGoodInPercent: boolean,
  showRawGoodInPercent: boolean,
  t: TFunction<"firefly-shared-ts-domain">
): Array<BarSeriesType | LineSeriesType> => {
  return useMemo(() => {
    const goodInPercentSeries = {
      id: "goodInPercent",
      xAxisId: "timeAxis",
      yAxisId: "percentage",
      label: t("Good ignitions in %"),
      type: "line",
      dataKey: "goodInPercent",
      connectNulls: true,
    } as LineSeriesType;

    const goodInPercentRawSeries = {
      id: "goodInPercentRaw",
      xAxisId: "timeAxis",
      yAxisId: "percentage",
      label: "Good ignitions in % (raw)",
      type: "line",
      dataKey: "goodInPercentRaw",
      connectNulls: true,
    } as LineSeriesType;

    const excellentSeries = {
      id: "excellent",
      xAxisId: "timeAxis",
      yAxisId: "burnCount",
      label: t("BurnAssessment.Good"),
      type: "bar",
      stack: "total",
      dataKey: "excellent",
      color: BurnAssessmentColor.Good,
    } as BarSeriesType;

    const goodSeries = {
      id: "good",
      xAxisId: "timeAxis",
      yAxisId: "burnCount",
      label: t("BurnAssessment.Normal"),
      type: "bar",
      stack: "total",
      dataKey: "good",
      color: BurnAssessmentColor.Normal,
    } as BarSeriesType;

    const badSeries = {
      id: "bad",
      xAxisId: "timeAxis",
      yAxisId: "burnCount",
      label: t("BurnAssessment.Bad"),
      type: "bar",
      stack: "total",
      dataKey: "bad",
      color: BurnAssessmentColor.Bad,
    } as BarSeriesType;

    const unknownSeries = {
      id: "unknown",
      xAxisId: "timeAxis",
      yAxisId: "burnCount",
      label: t("BurnAssessment.Unknown"),
      type: "bar",
      stack: "total",
      dataKey: "unknown",
      color: BurnAssessmentColor.Unknown,
    } as BarSeriesType;

    const arrayOfSeries: Array<LineSeriesType | BarSeriesType> = [];

    if (showGoodInPercent) {
      arrayOfSeries.push(goodInPercentSeries);
    }
    if (showGoodInPercent && showRawGoodInPercent) {
      arrayOfSeries.push(goodInPercentRawSeries);
    }

    if (showIgnitionQualityCount) {
      arrayOfSeries.push(unknownSeries);
      arrayOfSeries.push(badSeries);
      arrayOfSeries.push(goodSeries);
      arrayOfSeries.push(excellentSeries);
    }
    return arrayOfSeries;
  }, [t, showGoodInPercent, showRawGoodInPercent, showIgnitionQualityCount]);
};
