import React, { FC } from "react";
import { MuiChartContainer } from "shared-ts-mui";
import {
  axisClasses,
  BarPlot,
  ChartsAxisHighlight,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  MarkElement,
  MarkPlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { DatasetType } from "@mui/x-charts/models/seriesType/config";
import { MarkElementProps } from "@mui/x-charts/LineChart/MarkElement";
import { Stack } from "@mui/material";
import { SxProps } from "@mui/system";
import { BurnQualityCountDataPoints } from "../BurnQualityCountDataPoints";
import { DateTimeUnit, Interval } from "luxon";
import { useTranslation } from "react-i18next";
import { useResolveDisplayInterval } from "./useResolveDisplayInterval";
import { useTimeAxisData } from "./useTimeAxisData";
import { useXAxis } from "./useXAxis";
import { useYAxis } from "./useYAxis";
import { useSeries } from "./useSeries";
import { useDataSet } from "./useDataSet";

export interface BurnQualityCountDataPointsChartProps {
  dataPoints: BurnQualityCountDataPoints;
  interval: Interval<true>;
  timeUnit: DateTimeUnit;
  loading?: boolean;
  layout?: SizeClass;
  hideYAxisLabels?: boolean;
  hideXAxisLabels?: boolean;
  hideXAxis?: boolean;
  sx?: SxProps;
}

export const BurnQualityCountDataPointsChart: FC<
  BurnQualityCountDataPointsChartProps
> = (props) => {
  const { dataPoints, interval, timeUnit, sx } = props;
  const { t } = useTranslation("firefly-shared-ts-domain");
  const showGoodInPercent = true;
  const showRawGoodInPercent = false;
  const layout = props.layout ?? SizeClass.ExtraLarge;
  const loading = props.loading ?? false;
  const displayInterval = useResolveDisplayInterval(interval, timeUnit);
  const hideYAxisLabels = props.hideYAxisLabels ?? false;
  const hideXAxis = props.hideXAxis ?? false;
  const hideXAxiLabels = props.hideXAxisLabels ?? false;
  const dataSet = useDataSet(dataPoints, interval, timeUnit);
  const timeAxisData = useTimeAxisData(displayInterval, timeUnit);
  const xAxis = useXAxis(timeAxisData, interval, timeUnit, layout);
  const yAxis = useYAxis(showGoodInPercent, t);
  const series = useSeries(showGoodInPercent, showRawGoodInPercent, t);

  return (
    <Stack direction={"column"} sx={sx}>
      <MuiChartContainer
        loading={loading}
        sx={{ flexGrow: 1, minHeight: 0, minWidth: 0 }}
      >
        <ResponsiveChartContainer
          margin={
            layout === SizeClass.Compact
              ? {
                  top: 20,
                  left: 0,
                  right: 40,
                  bottom: hideXAxis ? 5 : 30,
                }
              : {
                  top: 20,
                  left: 0,
                  right: 75,
                  bottom: hideXAxis ? 5 : 90,
                }
          }
          dataset={dataSet as unknown as DatasetType}
          series={series}
          xAxis={xAxis}
          yAxis={yAxis}
          sx={{
            ".MuiMarkElement-root": {
              scale: layout === SizeClass.Compact ? "0.6" : "1.2",
            },
            ".MuiLineElement-root": {
              strokeWidth: layout === SizeClass.Compact ? 2 : 5,
              filter: "drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.9))",
            },
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              // Move the y-axis label with CSS
              transform: "translateX(-20px)",
            },
            [`.${axisClasses.right} .${axisClasses.label}`]: {
              // Move the y-axis label with CSS
              transform: "translateX(20px)",
            },
          }}
        >
          {!hideXAxis && (
            <ChartsXAxis
              label={
                hideXAxiLabels
                  ? undefined
                  : timeUnit === "week"
                  ? t("Week")
                  : t("Month")
              }
              position="bottom"
              axisId="timeAxis"
            />
          )}
          <ChartsYAxis
            label={hideYAxisLabels ? undefined : t("Percentage")}
            position="right"
            axisId="percentage"
          />
          <BarPlot borderRadius={6} />
          <LinePlot />
          <MarkPlot
            slots={{
              mark: (props: MarkElementProps) => {
                return <MarkElement {...props} />;
              },
            }}
          />
          <ChartsAxisHighlight x={"line"} />
          <ChartsTooltip trigger={"axis"} />
        </ResponsiveChartContainer>
      </MuiChartContainer>
    </Stack>
  );
};
