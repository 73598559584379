import { DateTime, DateTimeUnit, Interval } from "luxon";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";

export const timeAxisValueFormatter = (
  value: number,
  interval: Interval<true>,
  timeUnit: DateTimeUnit,
  layout: SizeClass
) => {
  if (timeUnit === "week") {
    return `${value}`;
  } else {
    const wholePart = Math.floor(value);
    const fractionPart = value - wholePart;
    const month = Math.round(fractionPart * 100);
    const intervalLessThanYear = interval.length("year") <= 1;
    return DateTime.local(wholePart, month)
      .toFormat(
        intervalLessThanYear
          ? SizeClass.Compact
            ? "MMM"
            : "MMMM"
          : layout === SizeClass.Compact
          ? "MMM yy"
          : "MMMM yyyy"
      )
      .capitalizeFirstLetter();
  }
};
