export const firePreventionStreetNames = [
  "Peiskosveien",
  "Tyristubben",
  "Spjeldet",
  "Sprengfyrerveien",
  "Grueveien",
  "Fyrstikkveien",
  "Beksotveien",
  "Røykskyveien",
  "Kakkelovnveien",
  "Feiermester Hansens vei",
  "Skorsteinssvingen",
  "Glødhaugen",
  "Furulunden",
  "Sotlundveien",
  "Glørmyra",
  "Kvistkroken",
  "Feierstien",
  "Askebrettveien",
  "Pipehatten",
];
