import { BarSeriesType } from "@mui/x-charts/models/seriesType/bar";
import { LineSeriesType } from "@mui/x-charts/models/seriesType/line";
import { useMemo } from "react";
import { TFunction } from "i18next";

export const useSeries = (
  showGoodInPercent: boolean,
  showRawGoodInPercent: boolean,
  t: TFunction<"firefly-shared-ts-domain">
): Array<BarSeriesType | LineSeriesType> => {
  return useMemo(() => {
    const goodInPercentSeries = {
      id: "goodInPercent",
      xAxisId: "timeAxis",
      yAxisId: "percentage",
      label: t("Good ignitions in %"),
      type: "line",
      dataKey: "goodInPercent",
      connectNulls: true,
    } as LineSeriesType;

    const goodInPercentRawSeries = {
      id: "goodInPercentRaw",
      xAxisId: "timeAxis",
      yAxisId: "percentage",
      label: "Good ignitions in % (raw)",
      type: "line",
      dataKey: "goodInPercentRaw",
      connectNulls: true,
    } as LineSeriesType;

    const arrayOfSeries: Array<LineSeriesType | BarSeriesType> = [];

    if (showGoodInPercent) {
      arrayOfSeries.push(goodInPercentSeries);
    }
    if (showGoodInPercent && showRawGoodInPercent) {
      arrayOfSeries.push(goodInPercentRawSeries);
    }

    return arrayOfSeries;
  }, [t, showGoodInPercent, showRawGoodInPercent]);
};
