import { ReactNode, useContext } from "react";
import { ModalContext } from "./AppLayoutModalProvider";
import { IllegalStateError } from "@airmont/shared/ts/utils/core";

export interface UseModalReturnType {
  modal?: ReactNode;
  setModal: (modal: ReactNode | undefined) => void;
  closeModal: () => void;
}

export const useAppLayoutModal = (): UseModalReturnType => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new IllegalStateError("useModal must be used within a ModalProvider");
  }
  return context;
};
