import { useMemo } from "react";
import { TimeWheel } from "@airmont/shared/ts/utils/luxon";
import { DateTimeUnit, Interval } from "luxon";

export const useTimeAxisData = (
  interval: Interval<true>,
  timeUnit: DateTimeUnit
): Array<number> => {
  return useMemo(() => {
    return new TimeWheel({
      start: interval.start,
      timeUnit: timeUnit,
    }).runUntilTime(interval.end, (dateTime) => {
      return timeUnit === "week"
        ? dateTime.weekNumber
        : dateTime.year + dateTime.month * 0.01;
    });
  }, [interval.start, interval.end, timeUnit]);
};
