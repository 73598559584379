import {
  BurnQualityCountDataPoint,
  burnQualityCountDataPointWithNoAssessedIgnitions,
} from "./BurnQualityCountDataPoint";
import { BurnQualityCountAggregateByTime } from "./BurnQualityCountAggregateByTime";
import {
  IllegalStateError,
  MathUtils,
  notNullOrUndefined,
} from "@airmont/shared/ts/utils/core";
import { merge, padStart } from "lodash";
import { DateTime, DateTimeUnit, Interval } from "luxon";
import { TimeWheel } from "@airmont/shared/ts/utils/luxon";

export interface BalanceGoodInPercentOptions {
  thresholdFactor: number;
}

export class BurnQualityCountDataPoints extends Array<BurnQualityCountDataPoint> {
  public static readonly DefaultBalanceGoodInPercentOptions: BalanceGoodInPercentOptions =
    {
      thresholdFactor: 0.9,
    };
  private readonly options: BalanceGoodInPercentOptions;

  constructor(options?: BalanceGoodInPercentOptions) {
    super();
    this.options =
      merge(
        {},
        BurnQualityCountDataPoints.DefaultBalanceGoodInPercentOptions,
        options
      ) ?? BurnQualityCountDataPoints.DefaultBalanceGoodInPercentOptions;
  }

  static empty(): BurnQualityCountDataPoints {
    return new BurnQualityCountDataPoints();
  }

  static from(
    aggregates: Array<BurnQualityCountAggregateByTime>
  ): BurnQualityCountDataPoints {
    return BurnQualityCountDataPoints.fromAggregates(aggregates);
  }

  static fromAggregates(
    aggregates: Array<BurnQualityCountAggregateByTime>,
    options?: BalanceGoodInPercentOptions
  ): BurnQualityCountDataPoints {
    if (aggregates === undefined) {
      return new BurnQualityCountDataPoints();
    }
    const dataPoints = new BurnQualityCountDataPoints(options);
    aggregates.forEach((aggregate) => {
      const goodIgnitions =
        aggregate.qualityCount.good + aggregate.qualityCount.excellent;
      const assessedIgnitions = goodIgnitions + aggregate.qualityCount.bad;
      const goodInPercent =
        assessedIgnitions === 0
          ? null
          : MathUtils.round((goodIgnitions / assessedIgnitions) * 100);

      dataPoints.push({
        time: aggregate.time,
        excellent: aggregate.qualityCount.excellent,
        good: aggregate.qualityCount.good,
        bad: aggregate.qualityCount.bad,
        unknown: aggregate.qualityCount.unknown,
        avgAssessedIgnitions: -1,
        assessedIgnitions: assessedIgnitions,
        goodIgnitions: goodIgnitions,
        goodInPercentRaw: goodInPercent,
        goodInPercent: goodInPercent,
        cumulativeGoodInPercent: goodInPercent,
      });
    });
    return dataPoints
      .calculateBalancedGoodInPercent()
      .calculateCumulativeInPercent();
  }

  first(): BurnQualityCountDataPoint {
    if (this.length === 0) {
      throw new IllegalStateError("No data points");
    }
    return this[0];
  }

  last(): BurnQualityCountDataPoint {
    if (this.length === 0) {
      throw new IllegalStateError("No data points");
    }
    return this[this.length - 1];
  }

  lastWithAssessedIgnitions(): BurnQualityCountDataPoint | undefined {
    if (this.length === 0) {
      return undefined;
    }
    const index = this.findLastIndex((it) => {
      return it.assessedIgnitions > 0;
    });
    return index > -1 ? this[index] : undefined;
  }

  lastOfYear(year: number): BurnQualityCountDataPoint | undefined {
    if (this.length === 0) {
      return undefined;
    }
    let lastOfYear: BurnQualityCountDataPoint | undefined = undefined;
    for (const item of this) {
      if (
        item.time.toLocal().year === year &&
        (lastOfYear === undefined || item.time > lastOfYear.time)
      ) {
        lastOfYear = item;
      }
    }
    return lastOfYear;
  }

  findIndexOfTime(time: DateTime<true>): number {
    return this.findIndex((it) => {
      if (time != null) {
        return it.time.equals(time.toUTC());
      }
      return false;
    });
  }

  findByTime(time: DateTime<true>): BurnQualityCountDataPoint | undefined {
    return this.find((it) => {
      if (time != null) {
        return it.time.equals(time.toUTC());
      }
      return false;
    });
  }

  sumAssessedIgnitions(endIndex?: number): number {
    return this.reduce((a, b, index) => {
      if (endIndex !== undefined && index > endIndex) {
        return a;
      }
      return a + b.assessedIgnitions;
    }, 0);
  }

  sumGoodIgnitions(endIndex?: number): number {
    return this.reduce((a, b, index) => {
      if (endIndex !== undefined && index > endIndex) {
        return a;
      }
      return a + b.goodIgnitions;
    }, 0);
  }

  avgAssessedIgnitions(endIndex?: number): number {
    const totalNumberOfIgnitions =
      endIndex !== undefined ? endIndex + 1 : this.length;
    return this.sumAssessedIgnitions(endIndex) / totalNumberOfIgnitions;
  }

  avgGoodIgnitionsInPercent(): number {
    const sumGoodIgnitions = this.sumGoodIgnitions();
    const sumAssessedIgnitions = this.sumAssessedIgnitions();
    return (sumGoodIgnitions / sumAssessedIgnitions) * 100;
  }

  filter(
    predicate: (
      value: BurnQualityCountDataPoint,
      index: number,
      array: BurnQualityCountDataPoint[]
    ) => boolean,
    thisArg?: BurnQualityCountDataPoints
  ): BurnQualityCountDataPoints {
    const filteredItems = super.filter(predicate, thisArg);
    const newDataPoints = new BurnQualityCountDataPoints();
    newDataPoints.push(...filteredItems);
    return newDataPoints;
  }

  between(
    interval: Interval<true>,
    frequency: DateTimeUnit
  ): BurnQualityCountDataPoints {
    const newDataPoints = BurnQualityCountDataPoints.empty();
    const timeWheelStart = interval.start.startOf(frequency);
    new TimeWheel({ start: timeWheelStart, timeUnit: frequency }).runUntilTime(
      interval.end,
      (dateTime) => {
        const dateTimeAsUtc = dateTime.toUTC();
        // Only add data points that are inside the interval
        if (interval.contains(dateTime)) {
          const existing = this.find((it) => it.time.equals(dateTimeAsUtc));
          if (existing === undefined) {
            newDataPoints.push(
              burnQualityCountDataPointWithNoAssessedIgnitions(dateTimeAsUtc)
            );
          } else {
            newDataPoints.push(existing);
          }
        }
      }
    );

    return newDataPoints;
  }

  findFirstFrom(
    predicate: (item: BurnQualityCountDataPoint) => boolean,
    startIndex: number = this.length - 1
  ): BurnQualityCountDataPoint | undefined {
    for (let i = startIndex; i >= 0; i--) {
      const it = this[i];
      if (predicate(it)) {
        return it;
      }
    }
    return undefined;
  }

  toString(timeUnit?: string): string {
    let str =
      "|  i | time (local) | avg. #ignitions | #ignitions | #good | good raw % | good bal % |\n";
    str +=
      "--------------------------------------------------------------------------------------\n";
    for (let i = 0; i < this.length; i++) {
      const item = this[i];
      const timeAsLocal = item.time.toLocal();
      const timeAsString =
        timeUnit === "week"
          ? timeAsLocal.toFormat("yyyy:WW")
          : timeAsLocal.toFormat("yyyy:MM").capitalizeFirstLetter();
      str += `| ${padStart(i.toString(), 2)} | ${padStart(
        timeAsString,
        12
      )} | ${padStart(
        Math.round(item.avgAssessedIgnitions).toString(),
        15
      )} | ${padStart(
        Math.round(item.assessedIgnitions).toString(),
        10
      )} | ${padStart(
        Math.round(item.goodIgnitions).toString(),
        5
      )} | ${padStart(
        notNullOrUndefined(item.goodInPercentRaw, (it) => it.toString()) ?? "-",
        9
      )} | ${padStart(
        notNullOrUndefined(item.goodInPercent, (it) => it.toString()) ?? "-",
        9
      )} | ${padStart(
        notNullOrUndefined(item.cumulativeGoodInPercent, (it) =>
          it.toString()
        ) ?? "-",
        9
      )} |\n`;
    }
    return str;
  }

  private calculateCumulativeInPercent(): BurnQualityCountDataPoints {
    const newDataPoints = new BurnQualityCountDataPoints(this.options);
    for (let index = 0; index < this.length; index++) {
      const curr = this[index];

      const sumGoodIgnitions = this.sumGoodIgnitions(index);
      const sumAssessedIgnitions = this.sumAssessedIgnitions(index);
      const goodOfTotalInPercent = MathUtils.round(
        (sumGoodIgnitions * 100) / sumAssessedIgnitions
      );

      newDataPoints.push({
        ...curr,
        cumulativeGoodInPercent: goodOfTotalInPercent,
      });
    }
    return newDataPoints;
  }

  private calculateBalancedGoodInPercent(): BurnQualityCountDataPoints {
    const newDataPoints = new BurnQualityCountDataPoints(this.options);
    for (let index = 0; index < this.length; index++) {
      const curr = this[index];
      const avgAssessedIgnitions = this.avgAssessedIgnitions(index - 1);
      const assessedIgnitionsThreshold =
        avgAssessedIgnitions * this.options.thresholdFactor;
      const significantFewerAssessedIgnitions =
        curr.assessedIgnitions <= assessedIgnitionsThreshold;

      if (!significantFewerAssessedIgnitions) {
        newDataPoints.push({
          ...curr,
          avgAssessedIgnitions: avgAssessedIgnitions,
        });
      } else {
        const previous =
          index === 0
            ? undefined
            : newDataPoints.findFirstFrom(
                (nearest) => nearest.goodInPercent != null,
                index - 1
              );

        if (curr.assessedIgnitions === 0) {
          newDataPoints.push({
            ...curr,
            avgAssessedIgnitions: avgAssessedIgnitions,
          });
        } else if (previous === undefined) {
          newDataPoints.push({
            ...curr,
            avgAssessedIgnitions: avgAssessedIgnitions,
          });
        } else {
          const factor = curr.assessedIgnitions / avgAssessedIgnitions;

          let goodInPercent: number | null =
            (curr.goodIgnitions / curr.assessedIgnitions) * 100;

          if (previous.goodInPercent == null) {
            goodInPercent = null;
          } else if (goodInPercent === 50) {
            goodInPercent = previous.goodInPercent;
          } else if (goodInPercent > 50) {
            const addition = factor === 1 ? 0 : goodInPercent * factor;
            goodInPercent = MathUtils.round(previous.goodInPercent + addition);
            goodInPercent = Math.min(curr.goodInPercentRaw ?? 0, goodInPercent);

            if (goodInPercent > 100) {
              goodInPercent = 100;
            }
          } else {
            const badInPercent = (curr.bad / curr.assessedIgnitions) * 100;

            const reduction = factor === 1 ? 0 : badInPercent * factor;
            goodInPercent = MathUtils.round(previous.goodInPercent - reduction);
            goodInPercent = Math.max(curr.goodInPercentRaw ?? 0, goodInPercent);
            if (goodInPercent < 0) {
              goodInPercent = 0;
            }
          }

          newDataPoints.push({
            ...curr,
            avgAssessedIgnitions: avgAssessedIgnitions,
            goodInPercent: goodInPercent,
          });
        }
      }
    }

    return newDataPoints;
  }
}
