import { useMemo } from "react";
import { BurnQualityCountDataPoints } from "../BurnQualityCountDataPoints";
import { DateTimeUnit, Interval } from "luxon";

export const useDataSet = (
  dataPoints: BurnQualityCountDataPoints | undefined,
  interval: Interval<true>,
  timeUnit: DateTimeUnit
): BurnQualityCountDataPoints => {
  return useMemo(() => {
    if (dataPoints === undefined) {
      return BurnQualityCountDataPoints.empty();
    }

    const limitedDataPoints = dataPoints.between(interval, timeUnit);
    console.log(
      "useDataSet: limitedDataPoints: \n",
      limitedDataPoints.toString(timeUnit)
    );
    return limitedDataPoints;
  }, [dataPoints, interval, timeUnit]);
};
