import { XAxisDefaultConfig } from "shared-ts-mui";
import { useMemo } from "react";
import { DateTimeUnit, Interval } from "luxon";
import { SizeClass } from "@airmont/shared/ts/ui/responsive";
import { timeAxisValueFormatter } from "./timeAxisValueFormatter";

export const useXAxis = (
  timeAxisData: Array<number>,
  interval: Interval<true>,
  timeUnit: DateTimeUnit,
  layout: SizeClass
): Array<XAxisDefaultConfig> => {
  return useMemo(() => {
    return [
      {
        id: "timeAxis",
        scaleType: "band",
        data: timeAxisData,
        valueFormatter: (value) =>
          timeAxisValueFormatter(value, interval, timeUnit, layout),
      } as XAxisDefaultConfig,
    ];
  }, [interval, layout, timeAxisData, timeUnit]);
};
