import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { AccessToken } from "shared-ts-utils-authentication";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import {
  anonymizeStreetAddress,
  SensorId,
} from "@airmont/firefly/shared/ts/domain";
import { ChimneySensorStatusQueryDto } from "./ChimneySensorStatusQuery";
import { ChimneySensorStatusDto } from "./ChimneySensorStatus";
import {
  IllegalStateError,
  NotFoundError,
  NullError,
} from "@airmont/shared/ts/utils/core";
import { QueryResult } from "shared/ts/utils/query";

const anonymize = localStorage.getItem("anonymize") === "true";

export class ChimneySensorStatusDao {
  private readonly fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: AccessToken;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.fetchHelper = new FetchHelper({ basePath: "/api/" })
      .authorizationBearer(args.accessToken)
      .addHeader("Firefly-Environment", args.customerEnvironment);
  }

  getById(id: SensorId): Promise<ChimneySensorStatusDto> {
    const query: ChimneySensorStatusQueryDto = {
      id: id,
    };
    return this.querySingle(query).then((result) => {
      if (result === undefined) {
        throw new NotFoundError("Did not find ChimneySensor with id: " + id);
      } else {
        return result;
      }
    });
  }

  query(
    query: ChimneySensorStatusQueryDto & {
      page: { fromIndex: number; size: number };
    }
  ): Promise<QueryResult<ChimneySensorStatusDto>> {
    return this.fetchHelper
      .post("chimney-sensor-status/query", query)
      .fetchAsJson<QueryResult<ChimneySensorStatusDto>>()
      .then((result) => {
        if (anonymize) {
          return {
            ...result,
            result: result.result.map((it) => {
              return {
                ...it,
                streetAddress: anonymizeStreetAddress(
                  it.streetAddress,
                  it.flueId
                ),
              };
            }),
          };
        }
        return result;
      });
  }

  querySingle(
    query: ChimneySensorStatusQueryDto
  ): Promise<ChimneySensorStatusDto | undefined> {
    return this.fetchHelper
      .post("chimney-sensor-status/query", query)
      .fetchAsJson<QueryResult<ChimneySensorStatusDto>>()
      .then((response) => {
        if (response.result == null) {
          throw new NullError(
            "QueryResult contains not data: \n" + JSON.stringify(response)
          );
        }
        if (response.result.length === 0) {
          return undefined;
        }
        if (response.result.length > 1) {
          throw new IllegalStateError(
            `Query single ChimneySensor ${JSON.stringify(
              query
            )}, unexpectedly resulted in more than one: ` +
              response.result.length
          );
        }

        return response.result[0];
      });
  }
}
