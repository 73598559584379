import { useMemo } from "react";
import { DateTimeUnit, Interval } from "luxon";

export const useResolveDisplayInterval = (
  interval: Interval<true>,
  timeUnit: DateTimeUnit
): Interval<true> => {
  return useMemo(() => {
    return Interval.fromDateTimes(
      interval.start.startOf(timeUnit),
      interval.end
    ) as Interval<true>;
  }, [interval.end, interval.start, timeUnit]);
};
