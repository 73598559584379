import { FetchHelper } from "@airmont/shared/ts/utils/fetch";
import { AccessToken } from "shared-ts-utils-authentication";
import { CustomerEnvironmentName } from "@airmont/firefly/shared/ts/customer-environment";
import {
  IllegalStateError,
  NotFoundError,
  NullError,
} from "@airmont/shared/ts/utils/core";
import { FlueStatusDto } from "./FlueStatus";
import { FlueStatusQueryDto } from "./FlueStatusQuery";
import {
  anonymizeStreetAddress,
  FlueId,
} from "@airmont/firefly/shared/ts/domain";
import { PageDto, QueryResult } from "shared/ts/utils/query";

const anonymize = localStorage.getItem("anonymize") === "true";

export class FlueStatusDao {
  fetchHelper: FetchHelper;

  constructor(args: {
    accessToken: AccessToken;
    customerEnvironment: CustomerEnvironmentName;
  }) {
    this.fetchHelper = new FetchHelper({ basePath: "/api/" })
      .authorizationBearer(args.accessToken)
      .addHeader("Firefly-Environment", args.customerEnvironment);
  }

  getById(id: FlueId): Promise<FlueStatusDto> {
    const query: FlueStatusQueryDto = {
      id: id,
    };
    return this.querySingle(query).then((result) => {
      if (result === undefined) {
        throw new NotFoundError("Did not find Flue with id: " + id);
      } else {
        return result;
      }
    });
  }

  query(
    query: FlueStatusQueryDto & { page: PageDto }
  ): Promise<QueryResult<FlueStatusDto>> {
    return this.fetchHelper
      .post("flue-status/query", query)
      .fetchAsJson<QueryResult<FlueStatusDto>>()
      .then((result) => {
        if (result.result == null) {
          throw new NullError(
            "QueryResult contains not data: \n" + JSON.stringify(result)
          );
        }

        if (anonymize) {
          return {
            ...result,
            result: result.result.map((it) => {
              return {
                ...it,
                streetAddress: anonymizeStreetAddress(it.streetAddress, it.id),
              };
            }),
          };
        }
        return result;
      });
  }

  querySingle(query: FlueStatusQueryDto): Promise<FlueStatusDto | undefined> {
    return this.fetchHelper
      .post("flue-status/query", query)
      .fetchAsJson<QueryResult<FlueStatusDto>>()
      .then((response) => {
        if (response.result == null) {
          throw new NullError(
            "QueryResult contains not data: \n" + JSON.stringify(response)
          );
        }
        if (response.result.length === 0) {
          return undefined;
        }
        if (response.result.length > 1) {
          throw new IllegalStateError(
            `Query single Flue ${JSON.stringify(
              query
            )}, unexpectedly resulted in more than one: ` +
              response.result.length
          );
        }

        return response.result[0];
      });
  }
}
