import { ObjectUtils } from "./ObjectUtils";

export class ArrayUtils {
  static distinct<T = unknown>(array: Array<T>): Array<T> {
    return array.filter((it, index) => {
      if (typeof it === "string") {
        return array.indexOf(it) === index;
      } else if (typeof it === "number") {
        return array.indexOf(it) === index;
      } else {
        return array.indexOf(it) === index;
      }
    });
  }

  static chunk<T>(array: Array<T>, size: number): Array<Array<T>> {
    if (size < 1) {
      throw new Error(`size cannot be less than 1: ${size}`);
    }

    const result: Array<Array<T>> = [];

    if (array.length <= size) {
      result.push(array);
    } else {
      const numberOfChunks = Math.ceil(array.length / size);

      for (let i = 0; i < numberOfChunks; i++) {
        const chunkStart = i * size;
        const chunkEnd = Math.min(chunkStart + size, array.length);
        const chunk = array.slice(chunkStart, chunkEnd);
        result.push(chunk);
      }
    }

    return result;
  }

  static copy<T>(array: Array<T>): Array<T> {
    return array.slice();
  }

  static copyAndPrepend<T>(array: Array<T>, item: T): Array<T> {
    const newArray = array.slice();
    newArray.unshift(item);
    return newArray;
  }

  static copyAndAppend<T>(array: Array<T>, item: T): Array<T> {
    const newArray = array.slice();
    newArray.push(item);
    return newArray;
  }

  static copyAndAppendAtIndex<T>(
    array: Array<T>,
    index: number,
    item: T
  ): Array<T> {
    const newArray = array.slice();
    newArray.splice(index, 0, item);
    return newArray;
  }

  static copyAndAppendIfNotExisting<T>(
    array: Array<T>,
    predicate: (item: T) => boolean,
    item: T
  ): Array<T> {
    const index = array.findIndex(predicate);
    if (index === -1) {
      const newArray = array.slice();
      newArray.push(item);
      return newArray;
    } else {
      return array;
    }
  }

  static copyAndUpdateElement<T>(
    array: Array<T>,
    predicate: (item: T, index: number) => boolean,
    newItem: ((item: T) => T) | T
  ): Array<T> {
    return array.map((item: T, index: number) => {
      if (predicate(item, index)) {
        if (typeof newItem === "function") {
          const newItemProvider: (item: T) => T = newItem as any;
          return newItemProvider(item);
        }

        return newItem as any;
      }

      return item;
    });
  }

  static copyAndUpdateOrAdd<T>(
    array: Array<T>,
    predicate: (item: T, index: number) => boolean,
    newItem: ((item: T | null | undefined) => T) | T
  ): Array<T> {
    const existingItem = array.find(predicate);

    if (existingItem == null) {
      const newArray = array.slice();

      if (typeof newItem === "function") {
        const newItemProvider: (item: T | null | undefined) => T =
          newItem as any;
        newArray.push(newItemProvider(null));
      } else {
        newArray.push(newItem);
      }

      return newArray;
    }

    return this.copyAndUpdateElement(array, predicate, newItem);
  }

  static copyAndUpdateOrAddElements<T>(
    array: Array<T>,
    predicate: (item: T, index: number) => boolean,
    elements: Array<((item: T | null | undefined) => T) | T>
  ): Array<T> {
    let newArray = array.slice();
    elements.forEach((newItem: ((item: T | null | undefined) => T) | T) => {
      const existingItem = newArray.find(predicate);

      if (existingItem == null) {
        if (typeof newItem === "function") {
          const newItemProvider: (item: T | null | undefined) => T =
            newItem as any;
          newArray.push(newItemProvider(null));
        } else {
          newArray.push(newItem);
        }
      } else {
        newArray = this.updateElement(newArray, predicate, newItem);
      }
    });
    return newArray;
  }

  static copyAndRemoveByIndex<T>(array: Array<T>, index: number): Array<T> {
    const newArray = array.slice();
    newArray.splice(index, 1);
    return newArray;
  }

  static copyAndRemoveByPredicate<T>(
    array: Array<T>,
    predicate: (item: T, index: number) => boolean
  ): Array<T> {
    const newArray = array.slice();
    this.removeByPredicate(newArray, predicate);
    return newArray;
  }

  static cumulativeSum(array: Array<number | null | undefined>): Array<number> {
    let sum = 0;
    const cumulativeSum: Array<number> = [];
    array.map((e) => {
      sum = sum + (e ?? 0);
      cumulativeSum.push(sum);
    });
    return cumulativeSum;
  }

  static cumulativeSumKeepNulls(
    array: Array<number | null>
  ): Array<number | null> {
    let sum = 0;
    const cumulativeSum: Array<number | null> = [];
    array.map((e) => {
      if (e === null) {
        cumulativeSum.push(null);
      } else {
        sum = sum + (e ?? 0);
        cumulativeSum.push(sum);
      }
    });
    return cumulativeSum;
  }

  static updateElement<T>(
    array: Array<T>,
    predicate: (item: T, index: number) => boolean,
    newItem: ((item: T) => T) | T
  ): Array<T> {
    for (let index = 0; index < array.length; index++) {
      const item: T = array[index];

      if (predicate(item, index)) {
        if (typeof newItem === "function") {
          const newItemProvider: (item: T) => T = newItem as any;
          array[index] = newItemProvider(item);
        } else {
          array[index] = newItem;
        }
      }
    }

    return array;
  }

  static updateOrAdd<T>(
    array: Array<T>,
    predicate: (item: T, index: number) => boolean,
    newItem: ((item: T | null | undefined) => T) | T
  ): Array<T> {
    const existingItem = array.find(predicate);

    if (existingItem == null) {
      if (typeof newItem === "function") {
        const newItemProvider: (item: T | null | undefined) => T =
          newItem as any;
        array.push(newItemProvider(null));
      } else {
        array.push(newItem);
      }

      return array;
    }

    return this.updateElement(array, predicate, newItem);
  }

  static createAndFill<T>(
    size: number,
    entryProvider: (index: number, array: Array<T>) => T = (index) =>
      index as unknown as T
  ): Array<T> {
    const array: Array<T> = [];

    for (let i = 0; i < size; i++) {
      array.push(entryProvider(i, array));
    }

    return array;
  }

  static removeByIndex<T>(array: Array<T>, index: number) {
    array.splice(index, 1);
  }

  static removeByPredicate<T>(
    array: Array<T>,
    predicate: (item: T, index: number) => boolean
  ) {
    const index = array.findIndex(predicate);

    if (index > -1) {
      array.splice(index, 1);
    }
  }

  static toggleItemByPredicate<T>(
    array: Array<T>,
    predicate: (item: T, index: number) => boolean,
    item: T
  ) {
    const existingIndex = array.findIndex(predicate);
    if (existingIndex > -1) {
      ArrayUtils.removeByIndex(array, existingIndex);
    } else {
      array.push(item);
    }
  }

  static copyAndToggleItemByPredicate<T>(
    array: Array<T>,
    predicate: (item: T, index: number, array: Array<T>) => boolean,
    item: T
  ): Array<T> {
    const copy = array.slice();
    const existingIndex = copy.findIndex(predicate);
    if (existingIndex > -1) {
      ArrayUtils.removeByIndex(copy, existingIndex);
    } else {
      copy.push(item);
    }
    return copy;
  }

  static toObjectDictionary<T>(
    array: Array<T>,
    keyProvider: (arg0: T) => string
  ): Record<string, T> {
    const obj = {};
    ArrayUtils.populateObject(array, keyProvider, obj);
    return obj;
  }

  static populateObject<T>(
    array: Array<T>,
    keyProvider: (arg0: T) => string,
    objectToPopulate: Record<string, any>
  ) {
    array.forEach((entry) => {
      objectToPopulate[keyProvider(entry)] = entry;
    });
  }

  static equals(
    a: Array<any> | null | undefined,
    b: Array<any> | null | undefined
  ): boolean {
    if (a === b) {
      return true;
    }

    if (a == null || b == null) {
      return false;
    }

    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; ++i) {
      if (!ObjectUtils.equals(a[i], b[i])) {
        return false;
      }
    }

    return true;
  }

  static async asyncForEach<T>(
    array: Array<T>,
    callback: (item: T, index: number, array: Array<T>) => Promise<void>
  ) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  static findLastIndex<T>(
    array: Array<T>,
    predicate: (item: T, index: number) => boolean,
    fromIndex?: number
  ): number {
    for (
      let i = fromIndex != null ? fromIndex : array.length - 1;
      i >= 0;
      i--
    ) {
      if (predicate(array[i], i)) {
        return i;
      }
    }
    return -1; // Not found
  }
}
