import ElderlyRoundedIcon from "@mui/icons-material/ElderlyRounded";
import SensorsOffRoundedIcon from "@mui/icons-material/SensorsOffRounded";
import Battery0BarRoundedIcon from "@mui/icons-material/Battery0BarRounded";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import SensorsRoundedIcon from "@mui/icons-material/SensorsRounded";
import { _throw, IllegalArgumentError } from "@airmont/shared/ts/utils/core";

export enum ChimneySensorStatusEnum {
  Unknown = "Unknown",
  Starting = "Starting",
  FailedToStart = "FailedToStart",
  Active = "Active",
  Retired = "Retired",
  NotCommunicating = "NotCommunicating",
  Dead = "Dead",
}

export const ChimneySensorStatusEnumBySeverity: Record<string, number> = {
  [ChimneySensorStatusEnum.Unknown]: 0,
  [ChimneySensorStatusEnum.Active]: 1,
  [ChimneySensorStatusEnum.Retired]: 2,
  [ChimneySensorStatusEnum.Starting]: 3,
  [ChimneySensorStatusEnum.NotCommunicating]: 4,
  [ChimneySensorStatusEnum.FailedToStart]: 5,
  [ChimneySensorStatusEnum.Dead]: 6,
};

export type ChimneySensorStatusStrings = keyof typeof ChimneySensorStatusEnum;

export const resolveChimneySensorStatusIcon = (
  status: ChimneySensorStatusEnum
) => {
  if (status === ChimneySensorStatusEnum.Starting) {
    return HourglassEmptyRoundedIcon;
  } else if (status === ChimneySensorStatusEnum.FailedToStart) {
    return HourglassEmptyRoundedIcon;
  } else if (status === ChimneySensorStatusEnum.Retired) {
    return ElderlyRoundedIcon;
  } else if (status === ChimneySensorStatusEnum.NotCommunicating) {
    return SensorsOffRoundedIcon;
  } else if (status === ChimneySensorStatusEnum.Dead) {
    return Battery0BarRoundedIcon;
  } else {
    return SensorsRoundedIcon;
  }
};

export const resolveChimneySensorStatusColorOrThrow = (
  status: ChimneySensorStatusEnum
): string => {
  return (
    resolveChimneySensorStatusColor(status) ??
    _throw(
      new IllegalArgumentError("No color for ChimneySensorStatus: " + status)
    )
  );
};

export const resolveChimneySensorStatusColorOrDefault = (
  status: ChimneySensorStatusEnum,
  defaultColor: string
): string => {
  return resolveChimneySensorStatusColor(status) ?? defaultColor;
};
export const resolveChimneySensorStatusColor = (
  status: ChimneySensorStatusEnum,
  overrides?: Record<string, string | undefined>
): string | undefined => {
  if (overrides != null && status in overrides) {
    return overrides[status];
  }

  if (status === ChimneySensorStatusEnum.Active) {
    return "#2e7d32";
  } else if (status === ChimneySensorStatusEnum.NotCommunicating) {
    return "#ffa726";
  } else if (status === ChimneySensorStatusEnum.Dead) {
    return "#f44336";
  } else if (status === ChimneySensorStatusEnum.FailedToStart) {
    return "#e136f4";
  } else if (status === ChimneySensorStatusEnum.Starting) {
    return "#1d67fd";
  } else if (status === ChimneySensorStatusEnum.Retired) {
    return "#646464";
  } else if (status === ChimneySensorStatusEnum.Unknown) {
    return "#646464";
  } else {
    return undefined;
  }
};
