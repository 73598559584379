import { FlueId } from "./FlueId";
import { uniqueNamesGenerator } from "unique-names-generator";
import { firePreventionStreetNames } from "shared-ts-street-names-dictionary";

const anonymizedStreetNames: Record<FlueId, string> = {};
export const anonymizeStreet = (street: string, flueId: FlueId): string => {
  let anonymizedStreetName = anonymizedStreetNames[flueId];
  if (anonymizedStreetName == null) {
    anonymizedStreetName = uniqueNamesGenerator({
      dictionaries: [firePreventionStreetNames],
      separator: " ",
      style: "capital",
    });
    anonymizedStreetNames[flueId] = anonymizedStreetName;
  }
  return anonymizedStreetName;
};

export const anonymizeStreetAddress = (
  streetAddress: {
    street: string;
    houseNumber?: number;
    houseLetter?: string;
    houseSection?: string;
  },
  flueId: FlueId
): {
  street: string;
  houseNumber?: number;
  houseLetter?: string;
  houseSection?: string;
} => {
  return {
    street: anonymizeStreet(streetAddress.street, flueId),
    houseNumber: streetAddress.houseNumber,
    houseLetter: streetAddress.houseLetter,
    houseSection: streetAddress.houseSection,
  };
};
